<template>
  <el-dialog
    top="5vh"
    :close-on-click-modal="false"
    class="import-add"
    :visible.sync="dialogVisible"
  >
    <div class="title">考试时间</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>

    <div class="body">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="viewContent"
        :style="index % 2 === 1 ? ' height: 50px;' : ''"
      >
        <div class="nameText" :style="index > 0 ? 'border-top: 0px;' : ''">{{ item.name }}</div>
        <div class="contentText" :style="index > 0 ? 'border-top: 0px;' : ''">{{ item.title }}</div>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" @click="dialogVisible = false">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'LookView',
  data() {
    return {
      dialogVisible: false,
      list: [],
    }
  },
  methods: {
    show(item) {
      this.dialogVisible = true
      console.log(item)

      this.list = [
        {
          name: '理论考试地点',
          title: item.theoryAddress == null ? '无' : item.theoryAddress,
        },
        {
          name: '理论考试时间',
          title:
            item.theoryAddress == null ? '--' : item.beginTheoryTime + '~' + item.endTheoryTime,
        },
        {
          name: '技能考试地点',
          title: item.skillAddress == null ? '无' : item.skillAddress,
        },
        {
          name: '技能考试时间',
          title: item.skillAddress == null ? '--' : item.beginSkillTime + '~' + item.endSkillTime,
        },
        {
          name: '综合考试地点',
          title: item.synthesizeAddress == null ? '无' : item.synthesizeAddress,
        },
        {
          name: '综合考试时间',
          title:
            item.synthesizeAddress == null
              ? '--'
              : item.beginSynthesizeTime + '~' + item.endSynthesizeTime,
        },
      ]
    },
  },
}
</script>
<style lang="scss" scoped>
.import-add {
  ::v-deep.el-dialog {
    position: relative;
    width: 720px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .body {
      box-sizing: border-box;
      padding: 50px 50px 50px 50px;
      .viewContent {
        display: flex;
        font-size: 16px;
        height: 70px;
        .nameText {
          border-bottom: 1px solid #c9c9c9;
          border-top: 1px solid #c9c9c9;
          border-left: 1px solid #c9c9c9;
          width: 160px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .contentText {
          border: 1px solid #c9c9c9;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: left;
          padding: 20px;
        }
      }
    }
    .title {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: center;
      // border-top: 1px solid #c7c7c7;
    }
  }
}
</style>
