<template>
  <div class="training-manage">
    <table-list
      title="证书培训管理"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="dataList"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :options="{ selection: true, operatesWidth: 170, fixScrollTable: true }"
      :pager="pager"
      :tabs-list="tabsList"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <!-- <ShowDia ref="ShowDia" @success="getList" /> -->

    <look-view ref="lookView"></look-view>
  </div>
</template>
<script>
//表格tabs
const tabsList = [
  {
    prop: 'publishStatus',
    activeValue: '',
    children: [
      { value: 0, label: '编辑中' },
      { value: 1, label: '进行中' },
      { value: 2, label: '已完成' },
    ],
  },
  {
    prop: 'productAttributeCategoryId',
    activeValue: '',
    children: [
      { value: 3, label: '职业等级证书' },
      { value: 2, label: '专项职业能力证书' },
    ],
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '创建证书培训',
    type: 'primary',
    method: _this.handleAdd,
  },
  {
    label: '结训',
    type: 'primary',
    method: _this.handleEndTrain,
  },
  {
    label: '导出',
    type: 'primary',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '证书培训计划名称',
    prop: 'certPrivateTrainName',
  },
  {
    label: '适用证书',
    type: 'select',
    prop: 'certId',
    children: _this.certList.map(item => ({
      value: item.certId,
      label: item.certName,
    })),
  },
  {
    label: '考试时间',
    type: 'picker',
    prop: 'examTime',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'certPrivateTrainName',
    minWidth: 150,
    label: '证书培训名称',
    twoLines: true,
  },
  {
    prop: 'certName',
    minWidth: 150,
    label: '适用证书',
    twoLines: true,
  },
  {
    prop: 'certLevelShow',
    minWidth: 150,
    label: '适用等级',
  },
  {
    prop: 'appointNumber',
    minWidth: 76,
    label: '人数',
  },
  {
    prop: 'examTime',
    label: '考试时间',
    minWidth: 100,
    align: 'center',
    render: (h, { row }) => {
      return [
        h(
          'el-button',
          {
            props: { type: 'text' },
            on: {
              click: () => {
                _this.onLookView(row)
              },
            },
          },
          '查看',
        ),
      ]
    },
  },
  {
    prop: 'publishStatusShow',
    label: '状态',
    minWidth: 62,
  },
  {
    prop: 'publishUserName',
    minWidth: 80,
    label: '发布人',
  },
  {
    prop: 'createTime',
    label: '创建时间',
    minWidth: 150,
    sortable: 'sortable',
  },
]
// 表格操作按钮配置
//状态，0.编辑中1进行中2已完成
const operates = _this => [
  // {
  //   label: '设置展示',
  //   method: _this.handleShow,
  //   isShow: item => item.showFlag == 1 && item.publishStatus == 2,
  // },
  // {
  //   label: '已设置',
  //   method: _this.handleShow,
  //   isShow: item => item.showFlag == 2 && item.publishStatus == 2,
  // },
  {
    label: '编辑',
    method: _this.handleEdit,
    // isShow: item => item.status == 0 || item.status == 3,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    isShow: item => item.publishStatus == 0,
  },
  {
    label: '学习记录',
    method: _this.onLearningRecords,
    isShow: item => item.publishStatus != 0,
  },
]

import TableList from '@/components/TableList'
// import ShowDia from './components/ShowDia'

import LookView from './components/LookView/LookView.vue'
import {
  classCertQuery,
  classCertExport,
  classCertDelete,
  classCertCreate,
  classCertEndTrain,
  // certOrgQuery,
  certDropDownList,
} from '@/api/certCenter'
import to from 'await-to'
export default {
  name: 'CertTrainManager',
  components: {
    TableList,
    // ShowDia,
    LookView,
  },
  data() {
    return {
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
      btns,
      tabsList,
      searchForm,
      columns,
      operates,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      certList: [],
      dataList: [],
      selectList: [],
    }
  },
  mounted() {
    this.getCertList()
    this.getList()
  },
  methods: {
    async getCertList() {
      const [res, err] = await to(certDropDownList({}))
      if (err) return this.$message.warning(err.msg)
      this.certList = res.data
    },
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(classCertQuery({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total

      for (let i = 0; i < res.data.list.length; i++) {
        let itemInfo = res.data.list[i]
        if (itemInfo.certLevel == null) {
          itemInfo.certLevelShow = '--'
        }

        if (itemInfo.appointNumber == null) {
          itemInfo.appointNumber = '--'
        }
      }
      this.dataList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      console.log(this.queryParams)
      if (queryParams.examTime && queryParams.examTime !== 0) {
        this.queryParams.beginExamTime = queryParams.examTime[0]
        this.queryParams.endExamTime = queryParams.examTime[1].split(' ')[0] + ' 23:59:59'
      } else {
        this.queryParams.beginExamTime = ''
        this.queryParams.endExamTime = ''
      }
      this.getList()
    },
    async handleAdd() {
      const [res, err] = await to(classCertCreate({}))
      if (err) return this.$message.warning(err.msg)
      this.$router.push(`/certCenter/certTrainManager/${res.data}/${1}`)
    },
    // handleShow(row) {
    //   this.$refs.ShowDia.show(row.classCertId)
    // },
    handleEdit(row) {
      this.$router.push(`/certCenter/certTrainManager/${row.certPrivateTrainId}/${0}`)
    },
    handleDelete({ certPrivateTrainId }) {
      this.$confirm('您确定要删除吗？删除后将不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(classCertDelete({ certPrivateTrainId }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    async handleExport() {
      let certPrivateTrainIds = []
      console.log(this.selectList)
      this.selectList.map(v => certPrivateTrainIds.push(v.certPrivateTrainId))
      const { current, size } = this.pager
      const [res, err] = await to(
        classCertExport({ current, size, ...this.queryParams, certPrivateTrainIds }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '证书培训管理导出.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    onLookView(row) {
      console.log(row)
      this.$refs.lookView.show(row)
    },
    onLearningRecords(row) {
      console.log('学习记录')
      this.$router.push({
        path: '/certCenter/certStudyStatiscs',
        query: {
          privateTrainId: row.certPrivateTrainId,
        },
      })
    },
    // handleNotice({ classCertId }) {
    //   this.$router.push(`/certCenter/certTrainManager/notice?classCertId=${classCertId}`)
    // },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
    async handleEndTrain() {
      console.log('结训')
      let certPrivateTrainIds = []
      console.log(this.selectList)
      this.selectList.map(v => certPrivateTrainIds.push(v.certPrivateTrainId))
      const [, err] = await to(classCertEndTrain({ certPrivateTrainIds }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('结训成功')
      this.getList()
    },
  },
}
</script>
